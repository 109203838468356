export const forexData = [
  {
    label: 'Bitcoin',
    id: 'f1ff77b6-3ab4-4719-9ded-2fc7e71cff1f',
    symbol: 'BTC',
    url: 'https://bitcoin.org',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png',
  },
  {
    label: 'Ethereum',
    id: 'e991ba77-d384-48ff-b0a4-40e95ef6b7d6',
    symbol: 'ETH',
    url: 'https://www.ethereum.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  },
  {
    label: 'Tether USD₮',
    id: 'eefcc863-188e-4fbb-9a78-6e2cf12027a0',
    symbol: 'USDT',
    url: 'https://tether.to/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
  },
  {
    label: 'Binance Chain Native Token',
    id: '9ffe8a9d-16bc-42a7-9b12-1a41d766c590',
    symbol: 'BNB',
    url: 'https://www.binance.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
  },
  {
    label: 'USD Coin (CENTRE)',
    id: '9074b21b-8d15-4a5d-9394-3e8c5b9ee763',
    symbol: 'USDC',
    url: 'https://www.centre.io/usdc',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
  },
  {
    label: 'Cardano',
    id: '7e9d8207-7ec3-4419-b2e1-c626585f14cd',
    symbol: 'ADA',
    url: 'https://cardanofoundation.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png',
  },
  {
    label: 'Solana',
    id: 'f7253e4e-9f54-4621-bf26-a6e112bb6866',
    symbol: 'SOL',
    url: 'https://solana.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png',
  },
  {
    label: 'XRP',
    id: 'e96efbf4-8f71-40bf-9b69-cdc3a6894655',
    symbol: 'XRP',
    url: 'https://ripple.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/52.png',
  },
  {
    label: 'Terra',
    id: 'fcf09f00-152b-48af-80c5-4a355d6ac6b4',
    symbol: 'LUNA',
    url: 'https://terra.money/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4172.png',
  },
  {
    label: 'DogeCoin',
    id: '7bb2339e-b6eb-408c-836f-2894c8751c6d',
    symbol: 'DOGE',
    url: 'https://dogecoin.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png',
  },
  {
    label: 'Polkadot',
    id: '40524e82-6580-43d3-ae91-c2e9a1734002',
    symbol: 'DOT',
    url: 'https://polkadot.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png',
  },
  {
    label: 'Avalanche',
    id: '2f6de64f-14d4-4ac0-870a-df7e7fe4a753',
    symbol: 'AVAX',
    url: 'https://www.avalabs.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
  },
  {
    label: 'Binance USD',
    id: 'bfbc70ab-ab4e-4e2d-9855-7a7023277fef',
    symbol: 'BUSD',
    url: 'https://www.paxos.com/busd/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png',
  },
  {
    label: 'Shiba Inu',
    id: '1971cb71-88cd-43bc-b354-8d7df9a0a97e',
    symbol: 'SHIB',
    url: 'https://shibatoken.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5994.png',
  },
  {
    label: 'Polygon',
    id: 'd95060a1-3f83-4a99-9a5c-d37902e08310',
    symbol: 'MATIC',
    url: 'https://matic.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
  },
  {
    label: 'TerraUSD',
    id: 'f0972ff4-11e8-44d8-9713-62297c7f744a',
    symbol: 'UST',
    url: 'https://terra.money/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7129.png',
  },
  {
    label: 'Wrapped Bitcoin',
    id: 'f16eefbf-a5bd-4b5a-b60b-6e3fa1c4edb3',
    symbol: 'WBTC',
    url: 'https://www.wbtc.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png',
  },
  {
    label: 'Cosmos',
    id: 'fe72aaa4-4c33-4d1e-88bd-54dccef6a0cf',
    symbol: 'ATOM',
    url: 'https://cosmos.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png',
  },
  {
    label: 'Dai',
    id: '6b02c360-89d6-4d64-ae9c-00536c4630cd',
    symbol: 'DAI',
    url: 'https://makerdao.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png',
  },
  {
    label: 'Crypto.com Chain',
    id: '616e0dbc-4a4c-45bd-a069-1cfbde9d8b8b',
    symbol: 'CRO',
    url: 'https://www.crypto.com/en/chain',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3635.png',
  },
  {
    label: 'ChainLink',
    id: 'bf5421f5-59ea-4e90-914b-da89e384df37',
    symbol: 'LINK',
    url: 'https://link.smartcontract.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png',
  },
  {
    label: 'Litecoin',
    id: 'e0c280b3-b4cf-4012-9e08-14a816ef2c9c',
    symbol: 'LTC',
    url: 'https://litecoin.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2.png',
  },
  {
    label: 'Near',
    id: '1cc3bea6-2921-4955-9c21-c58daebbdd29',
    symbol: 'NEAR',
    url: 'https://near.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6535.png',
  },
  {
    label: 'Uniswap Governance Token',
    id: 'a6cb9c1f-3660-4dc7-b510-b709bad767f2',
    symbol: 'UNI',
    url: 'https://app.uniswap.org/#/uni',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png',
  },
  {
    label: 'Algorand',
    id: '867c2836-e530-46ce-8215-064c017a4d55',
    symbol: 'ALGO',
    url: 'https://www.algorand.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4030.png',
  },
  {
    label: 'Tronix',
    id: 'a65e2724-9a24-4cc4-b9e9-330ccb027991',
    symbol: 'TRX',
    url: 'https://tron.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png',
  },
  {
    label: 'Fantom',
    id: '296c08da-8d09-4d1e-acf9-f7df784fd79f',
    symbol: 'FTM',
    url: 'https://fantom.foundation/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png',
  },
  {
    label: 'Bitcoin Cash',
    id: '776b26c5-1f21-411d-b5ef-bc2d757ab03e',
    symbol: 'BCH',
    url: 'https://www.bitcoincash.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1831.png',
  },
  {
    label: 'FTX Token',
    id: '8eef6ddd-7568-43cb-9f0e-d2c52df00736',
    symbol: 'FTT',
    url: 'https://ftx.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4195.png',
  },
  {
    label: 'Stellar',
    id: 'ca49e8ff-2664-410b-9b69-a2c65600bb59',
    symbol: 'XLM',
    url: 'https://stellar.org',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/512.png',
  },
  {
    label: 'Internet Computer',
    id: '52ab2ab8-f9d5-47fb-93dd-cb02d41e40ed',
    symbol: 'ICP',
    url: 'https://dfinity.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8916.png',
  },
  {
    label: 'Hedera Hashgraph',
    id: '70f2e9fe-7a85-4d2f-aa76-434a66165a22',
    symbol: 'HBAR',
    url: 'https://www.hedera.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4642.png',
  },
  {
    label: 'Decentraland',
    id: '05deac07-8498-4240-a853-d226566c3a81',
    symbol: 'MANA',
    url: 'https://decentraland.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1966.png',
  },
  {
    label: 'Bitcoin BEP2',
    id: '02c25751-365a-4a29-89e7-b6aa8e32592c',
    symbol: 'BTCB',
    url: 'https://www.binance.com/en/blog/347360878904684544/Introducing-B',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4023.png',
  },
  {
    label: 'VeChain',
    id: '2e382018-7c0f-471a-859f-cadd0f338fa9',
    symbol: 'VET',
    url: 'https://www.vechain.com',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3077.png',
  },
  {
    label: 'LEO Token',
    id: '574bfb8b-ed0a-490a-ba87-d6e1cbca75a8',
    symbol: 'LEO',
    url: 'https://www.bitfinex.com/wp-2019-05.pdf',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3957.png',
  },
  {
    label: 'Ethereum Classic',
    id: '5ac391c0-3cb8-4d76-94f1-52acdb6ac1fd',
    symbol: 'ETC',
    url: 'https://ethereumclassic.github.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1321.png',
  },
  {
    label: 'Axie Infinity',
    id: '143bac12-bbf6-4bbf-8ca8-eff1d89cc9b7',
    symbol: 'AXS',
    url: 'https://axieinfinity.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6783.png',
  },
  {
    label: 'Klaytn',
    id: '569c0168-0ab6-40f3-9129-c9bafd37701c',
    symbol: 'KLAY',
    url: 'https://www.klaytn.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4256.png',
  },
  {
    label: 'Filecoin',
    id: 'e807428b-c7a7-4702-8382-b61a71172614',
    symbol: 'FIL',
    url: 'https://filecoin.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2280.png',
  },
  {
    label: 'The Sandbox',
    id: '110a6c69-59ca-44d8-8770-8d844659d4ba',
    symbol: 'SAND',
    url: 'https://www.sandbox.game/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6210.png',
  },
  {
    label: 'Theta Token',
    id: '22fc1a1b-3326-4875-ae86-3a26510192f8',
    symbol: 'THETA',
    url: 'https://www.thetatoken.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2416.png',
  },
  {
    label: 'Monero',
    id: 'bf43c7fb-601e-41b0-96f1-34fad269cb13',
    symbol: 'XMR',
    url: 'http://www.monero.cc/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/328.png',
  },
  {
    label: 'Elrond',
    id: '0711289b-3266-42d2-8966-710e90891430',
    symbol: 'EGLD',
    url: 'https://elrond.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6892.png',
  },
  {
    label: 'Tezos',
    id: 'd84928db-a197-4caa-8319-c91bbe18c571',
    symbol: 'XTZ',
    url: 'https://www.tezos.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2011.png',
  },
  {
    label: 'Helium',
    id: 'b7b2f71e-ad5e-418f-b07b-4eb03e21eb32',
    symbol: 'HNT',
    url: 'https://www.helium.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5665.png',
  },
  {
    label: 'Menlo One ',
    id: 'cc397bb7-4016-4b4d-bffb-7192b38e2b33',
    symbol: 'ONE',
    url: 'https://menlo.one/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3945.png',
  },
  {
    label: 'BitTorrent',
    id: '8758ab4e-b08e-4cb6-a9de-a3b27ff512e8',
    symbol: 'BTT',
    url: 'https://www.bittorrent.com/bittorrent-free',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3718.png',
  },
  {
    label: 'EOS',
    id: 'ab8ac321-082b-4231-a31c-4c938cfceab7',
    symbol: 'EOS',
    url: 'https://eos.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1765.png',
  },
  {
    label: 'PancakeSwap',
    id: 'a3bc05da-4c3d-4618-940f-f486c93fe566',
    symbol: 'CAKE',
    url: 'https://pancakeswap.finance/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png',
  },
  {
    label: 'Aave',
    id: '2f947ebc-c949-4710-b594-f5f741b47064',
    symbol: 'AAVE',
    url: 'https://aave.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png',
  },
  {
    label: 'The Graph',
    id: '52cf7468-22cc-426e-9e55-ef331e058ef2',
    symbol: 'GRT',
    url: 'https://thegraph.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6719.png',
  },
  {
    label: 'Stox',
    id: 'dd7c7ffd-e0cc-4a22-bc28-cc884e29cf7f',
    symbol: 'STX',
    url: 'https://www.stox.com/en/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4847.png',
  },
  {
    label: 'Maker',
    id: 'e70483a2-70d8-4198-ac32-475d3bf4e5e3',
    symbol: 'MKR',
    url: 'https://makerdao.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png',
  },
  {
    label: 'Bitcoin SV',
    id: '317e8b2c-3c29-4f2f-be92-0b33abb90241',
    symbol: 'BSV',
    url: 'https://bitcoinsv.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3602.png',
  },
  {
    label: 'Flow',
    id: 'ce5f5839-b55e-4a72-8f6e-64793f14f7ca',
    symbol: 'FLOW',
    url: 'https://www.onflow.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4558.png',
  },
  {
    label: 'Kusama',
    id: '4023185d-5c8d-4abe-abae-54f723f7c40d',
    symbol: 'KSM',
    url: 'https://kusama.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5034.png',
  },
  {
    label: 'TrueUSD',
    id: '20890afa-1f97-4ea4-b777-675fb128691f',
    symbol: 'TUSD',
    url: 'https://www.trusttoken.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2563.png',
  },
  {
    label: 'eCash',
    id: '6b7dbe87-4e5b-4ea8-9baf-c092fb163db4',
    symbol: 'XEC',
    url: 'https://e.cash/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10791.png',
  },
  {
    label: 'Enjin Coin',
    id: '486eb2b8-1d76-4946-8b57-c8bf52465be4',
    symbol: 'ENJ',
    url: 'https://www.enjin.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2130.png',
  },
  {
    label: 'Curve DAO Token',
    id: '0d91370d-aa33-4474-9659-65dfebc934c6',
    symbol: 'CRV',
    url: 'https://www.curve.fi/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6538.png',
  },
  {
    label: 'Huobi Token',
    id: '31955521-fd69-4e31-900d-024be28ef47d',
    symbol: 'HT',
    url: 'https://www.huobi.pro/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2502.png',
  },
  {
    label: 'Gala',
    id: '11436f85-4009-47a5-9f36-61b19b77377a',
    symbol: 'GALA',
    url: 'https://gala.games/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7080.png',
  },
  {
    label: 'THORChain',
    id: '288e6844-fbe8-454e-b63c-9f065c854c74',
    symbol: 'RUNE',
    url: 'https://thorchain.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4157.png',
  },
  {
    label: 'Quant',
    id: '00eedfd3-9f55-4098-a32d-7e1492b5419f',
    symbol: 'QNT',
    url: 'https://www.quant.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3155.png',
  },
  {
    label: 'Celo',
    id: 'c4f3dc97-56fa-4ffa-ad18-f77154898998',
    symbol: 'CELO',
    url: 'https://celo.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5567.png',
  },
  {
    label: 'NEO',
    id: 'ccc44498-322c-4b6e-9977-cb1eba92f463',
    symbol: 'NEO',
    url: 'https://neo.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1376.png',
  },
  {
    label: 'Zcash',
    id: 'd594df0f-be90-4610-a450-2028b9d78890',
    symbol: 'ZEC',
    url: 'https://z.cash/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1437.png',
  },
  {
    label: 'KuCoin Token',
    id: '455be7c5-d0a5-4650-85a7-db362e9fad17',
    symbol: 'KCS',
    url: 'https://www.kucoin.com/#/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2087.png',
  },
  {
    label: 'OKB',
    id: '6c06cb0d-0ec1-415b-b661-a5dd2ccff3b6',
    symbol: 'OKB',
    url: 'https://www.okex.com/activity/okbBoss',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3897.png',
  },
  {
    label: 'Amp',
    id: '92b1d06f-15a6-4986-afc2-9ec0b4f2dd41',
    symbol: 'AMP',
    url: 'https://amptoken.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6945.png',
  },
  {
    label: 'Oasis Network',
    id: '075b98c7-614a-47d0-848e-a0146d4def7c',
    symbol: 'ROSE',
    url: 'https://oasisprotocol.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7653.png',
  },
  {
    label: 'Loopring ETH',
    id: 'f6e4f794-031c-4e11-af8a-d614432ad682',
    symbol: 'LRC',
    url: 'https://loopring.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1934.png',
  },
  {
    label: 'Basic Attention Token',
    id: 'a21865d1-53bd-4644-b164-7900296959b0',
    symbol: 'BAT',
    url: 'https://basicattentiontoken.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1697.png',
  },
  {
    label: 'Chiliz',
    id: '247ac20f-76c5-4103-a6d5-69e95a29ecda',
    symbol: 'CHZ',
    url: 'https://www.chiliz.com/en/home/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4066.png',
  },
  {
    label: 'Arweave',
    id: '812c9bc5-4eb7-4687-b851-576d30aa6a82',
    symbol: 'AR',
    url: 'https://www.arweave.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5632.png',
  },
  {
    label: 'Dash',
    id: '5945a133-f577-4462-b028-e2e23da8a48c',
    symbol: 'DASH',
    url: 'https://www.dash.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/131.png',
  },
  {
    label: 'Nexo',
    id: 'c0d91fc3-953e-4ff0-a89c-07c327e8059e',
    symbol: 'NEXO',
    url: 'https://nexo.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2694.png',
  },
  {
    label: 'Waves',
    id: '4a628582-9cd5-4ee3-9f90-859aa2cbcd0b',
    symbol: 'WAVES',
    url: 'https://wavesplatform.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1274.png',
  },
  {
    label: 'Kadena',
    id: '5143c612-186c-409a-b04c-f9e9290f6a53',
    symbol: 'KDA',
    url: 'https://www.kadena.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5647.png',
  },
  {
    label: 'yearn.finance',
    id: '151628de-7e86-43fb-9616-c1cf4e2733d1',
    symbol: 'YFI',
    url: 'https://yearn.finance/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5864.png',
  },
  {
    label: 'Mina Protocol',
    id: 'd5583479-f592-45fc-842d-ca2b718f37dd',
    symbol: 'MINA',
    url: 'https://minaprotocol.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8646.png',
  },
  {
    label: 'Secret Network',
    id: '801e0f55-d679-4048-a2ab-f45513de3001',
    symbol: 'SCRT',
    url: 'https://scrt.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5604.png',
  },
  {
    label: 'NEM',
    id: '49c2cafb-9186-4928-94b0-e1bf0332b68f',
    symbol: 'XEM',
    url: 'http://nem.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/873.png',
  },
  {
    label: 'Compound Governance Token',
    id: '5d47cf44-b4c3-4080-82ce-2133df78fede',
    symbol: 'COMP',
    url: 'https://compound.finance/governance/comp',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5692.png',
  },
  {
    label: 'Holo',
    id: 'b75037a0-cc86-44a6-9875-20af3e402c9b',
    symbol: 'HOT',
    url: 'https://holochain.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2682.png',
  },
  {
    label: '1inch',
    id: 'eedb57fe-6529-452b-a37d-01542c52a13d',
    symbol: '1INCH',
    url: 'https://1inch.exchange/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8104.png',
  },
  {
    label: 'Decred',
    id: 'adf0b848-05a4-4510-9804-79dbb7625ca3',
    symbol: 'DCR',
    url: 'https://www.decred.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1168.png',
  },
  {
    label: 'Theta Fuel',
    id: 'a447270c-ce58-4783-8166-c6f2bcd2a9f4',
    symbol: 'TFUEL',
    url: 'https://www.thetatoken.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3822.png',
  },
  {
    label: 'IoTeX',
    id: '19e4262d-b41f-4b60-872d-84ad48e7f4c9',
    symbol: 'IOTX',
    url: 'https://iotex.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2777.png',
  },
  {
    label: 'Ravencoin',
    id: '1566b4e6-ba00-4d92-b0f4-29c39744175f',
    symbol: 'RVN',
    url: 'https://ravencoin.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2577.png',
  },
  {
    label: 'OMG Network',
    id: 'dc4e66d8-5c2d-4229-966e-357897a922be',
    symbol: 'OMG',
    url: 'https://omg.omise.co/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1808.png',
  },
  {
    label: 'Celsius',
    id: '5a0db1b8-c576-4f6d-9a89-e3d2cd2a9649',
    symbol: 'CEL',
    url: 'https://celsius.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2700.png',
  },
  {
    label: 'SysCoin',
    id: 'b003e66c-4411-46e0-81b0-a8d77ffd9487',
    symbol: 'SYS',
    url: 'http://syscoin.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/541.png',
  },
  {
    label: 'Perpetual Protocol',
    id: '0d292ab8-b92c-4a46-8934-1880bf879cc8',
    symbol: 'PERP',
    url: 'https://perp.fi/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6950.png',
  },
  {
    label: 'SushiSwap',
    id: '4b7f5cf1-c64a-4de9-9363-478ad4542b89',
    symbol: 'SUSHI',
    url: 'https://sushiswap.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png',
  },
  {
    label: 'Bancor Network Token',
    id: 'fddadaf5-744c-41a4-ad5e-0fbd2d1ae6d3',
    symbol: 'BNT',
    url: 'https://www.bancor.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1727.png',
  },
  {
    label: 'Qtum',
    id: 'e9b0d261-0aed-4f4a-95aa-77390b687dc6',
    symbol: 'QTUM',
    url: 'https://qtum.org/en/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1684.png',
  },
  {
    label: 'Wax Token',
    id: 'd84d2b45-696c-43f6-a689-8d46f62cde72',
    symbol: 'WAXP',
    url: 'https://wax.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2300.png',
  },
  {
    label: 'Velas',
    id: 'b71ae3ef-ac43-44d4-8888-d23c8ceb9910',
    symbol: 'VLX',
    url: 'https://velas.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4747.png',
  },
  {
    label: 'Wootrade',
    id: '5e2045f3-84da-482f-a932-85c3872cb864',
    symbol: 'WOO',
    url: 'https://woo.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7501.png',
  },
  {
    label: 'Zilliqa',
    id: '45e0bd0f-77e7-4476-9f41-d762ffdf13ae',
    symbol: 'ZIL',
    url: 'https://www.zilliqa.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2469.png',
  },
  {
    label: 'Voyager Token',
    id: 'c2f8356a-99da-4c64-84dc-2d337ae2e46e',
    symbol: 'VGX',
    url: 'https://www.ethos.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1817.png',
  },
  {
    label: 'Ankr Network',
    id: '7f2b612c-93ed-4376-9c71-f1022347b29a',
    symbol: 'ANKR',
    url: 'https://www.ankr.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3783.png',
  },
  {
    label: 'Livepeer',
    id: 'e8e1bb1b-326d-4c12-8f59-9ab29a6ae30b',
    symbol: 'LPT',
    url: 'https://livepeer.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3640.png',
  },
  {
    label: 'SwissBorg',
    id: '91a142a5-2f3b-43fb-b851-5c5f75a1391d',
    symbol: 'CHSB',
    url: 'https://swissborg.com',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2499.png',
  },
  {
    label: 'Bitcoin Gold',
    id: 'd013a6e6-792f-46ce-a922-8c25d6969481',
    symbol: 'BTG',
    url: 'https://bitcoingold.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2083.png',
  },
  {
    label: 'Neutrino USD',
    id: '3a5e1b9e-05f3-43da-8a00-cc128eabe648',
    symbol: 'USDN',
    url: 'https://neutrino.at/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5068.png',
  },
  {
    label: 'Kava',
    id: '0aace354-2084-4b02-bdf3-0c776e902afc',
    symbol: 'KAVA',
    url: 'https://www.kava.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4846.png',
  },
  {
    label: 'ICON',
    id: '2a349780-3751-42e8-b62f-c9e28afd37f8',
    symbol: 'ICX',
    url: 'https://www.icon.foundation/en/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2099.png',
  },
  {
    label: 'AUDIUS',
    id: '1211017e-0e1c-459d-9684-7aa58bdc7efa',
    symbol: 'AUDIO',
    url: 'https://audius.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7455.png',
  },
  {
    label: 'Siacoin',
    id: '2d0a565d-d03e-497f-86ea-701acf72e542',
    symbol: 'SC',
    url: 'http://sia.tech/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1042.png',
  },
  {
    label: 'Immutable X',
    id: '3c4fc7ac-2ce8-4a48-afc9-4fd3bff25c7a',
    symbol: 'IMX',
    url: 'https://www.immutable.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10603.png',
  },
  {
    label: 'Revain',
    id: 'cc6b704c-0154-432e-a31b-d101c33021b1',
    symbol: 'REV',
    url: 'https://revain.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2135.png',
  },
  {
    label: 'Gatechain Token',
    id: '84f4f9ef-da4a-44fd-9f80-545dd2b888f3',
    symbol: 'GT',
    url: 'https://gatechain.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4269.png',
  },
  {
    label: 'Gnosis',
    id: 'e244fd6d-1f4f-4225-b18b-5f090275cb22',
    symbol: 'GNO',
    url: 'https://gnosis.pm/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1659.png',
  },
  {
    label: '0x',
    id: '0f538e2c-eb49-4aa6-ae6e-6015b659820a',
    symbol: 'ZRX',
    url: 'https://0xproject.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1896.png',
  },
  {
    label: 'Dogelon Mars',
    id: '41c88e85-ec6a-424c-a48f-b1ad6b0c7a94',
    symbol: 'ELON',
    url: 'https://dogelonmars.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9436.png',
  },
  {
    label: 'Synthetix Network Token',
    id: '4c3287c6-7f70-4439-8c32-e04b71071547',
    symbol: 'SNX',
    url: 'https://www.synthetix.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2586.png',
  },
  {
    label: 'Horizen',
    id: 'b8e745dc-43e6-4d57-92ec-e28e39c897f7',
    symbol: 'ZEN',
    url: 'https://zencash.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1698.png',
  },
  {
    label: 'Nervos Network',
    id: '6952018d-5c46-49fa-810a-faef34a204b4',
    symbol: 'CKB',
    url: 'http://nervos.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4948.png',
  },
  {
    label: 'dYdX',
    id: '3a91cc10-4918-4f3d-80c1-175f6eecd5a4',
    symbol: 'DYDX',
    url: 'https://dydx.exchange/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11156.png',
  },
  {
    label: 'Fei Protocol',
    id: 'd7ec9474-d15e-4525-b1e4-91d17941ccf2',
    symbol: 'FEI',
    url: 'https://fei.money/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8642.png',
  },
  {
    label: 'Telcoin',
    id: 'd1581eab-44fe-4725-8872-0199d8e21185',
    symbol: 'TEL',
    url: 'https://www.telco.in/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2394.png',
  },
  {
    label: 'Ontology',
    id: '81acf897-428e-4e6b-9565-752cc589a992',
    symbol: 'ONT',
    url: 'https://ont.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2566.png',
  },
  {
    label: 'IOStoken',
    id: '53c9e004-3596-4ca6-bcfa-7b7dbfc9a60f',
    symbol: 'IOST',
    url: 'http://iost.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2405.png',
  },
  {
    label: 'Universal Market Access',
    id: 'e769c421-588f-459a-b167-0b854261f46c',
    symbol: 'UMA',
    url: 'https://umaproject.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2405.png',
  },
  {
    label: 'Ocean Protocol',
    id: 'd3c4fd76-e5c8-4e19-9944-5c853f45a376',
    symbol: 'OCEAN',
    url: 'https://oceanprotocol.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3911.png',
  },
  {
    label: 'Storj',
    id: '5aa2a20f-912d-477c-80de-627db1e04cfb',
    symbol: 'STORJ',
    url: 'https://storj.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1772.png',
  },
  {
    label: 'SKALE',
    id: '0e8a6876-2469-4929-9dfe-a2b868490ef6',
    symbol: 'SKL',
    url: 'https://skale.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5691.png',
  },
  {
    label: 'Celer Network',
    id: '4c739bae-c23c-4238-a70d-e5395096c808',
    symbol: 'CELR',
    url: 'https://www.celer.network/#',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3814.png',
  },
  {
    label: 'Chromia',
    id: '5e9f02b2-890a-48af-83a6-0163771f33cb',
    symbol: 'CHR',
    url: 'https://chromia.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3978.png',
  },
  {
    label: 'NuCypher',
    id: '1cc57f05-880b-4dc8-80fe-3748a7c780ac',
    symbol: 'NU',
    url: 'https://www.nucypher.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4761.png',
  },
  {
    label: 'Hive',
    id: '2c325930-a290-4333-b4f5-6b7f45e96a22',
    symbol: 'HIVE',
    url: 'https://hive.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5370.png',
  },
  {
    label: 'PAX Gold',
    id: '062ff19a-a6bd-431e-9308-d69026c7ab99',
    symbol: 'PAXG',
    url: 'https://www.paxos.com/paxgold/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4705.png',
  },
  {
    label: 'Golem',
    id: '39719c00-f807-4468-b796-8616515a3107',
    symbol: 'GLM',
    url: 'https://golem.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1455.png',
  },
  {
    label: 'DigiByte',
    id: 'c594e061-0952-4495-b973-ee82c0adb52d',
    symbol: 'DGB',
    url: 'http://www.digibyte.co/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/109.png',
  },
  {
    label: 'Polymath',
    id: '6a42cdae-ae28-489b-a3da-52fe87aa50ea',
    symbol: 'POLY',
    url: 'https://www.polymath.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2496.png',
  },
  {
    label: 'Raydium',
    id: 'c51e3408-e2ce-4134-8596-b5571c7b9c79',
    symbol: 'RAY',
    url: 'https://raydium.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8526.png',
  },
  {
    label: 'Ren',
    id: 'eab7eddd-e325-40fd-80cb-fe28f36e3085',
    symbol: 'REN',
    url: 'https://renproject.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2539.png',
  },
  {
    label: 'Serum',
    id: 'a68ea850-ec3f-4f18-b50b-2aeceb0dad4f',
    symbol: 'SRM',
    url: 'https://projectserum.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6187.png',
  },
  {
    label: 'Just Governance Token',
    id: '4de45e1f-8cad-4427-950f-32e8ca0def57',
    symbol: 'JST',
    url: 'https://www.just.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5488.png',
  },
  {
    label: 'XYO Network',
    id: 'f0a381d0-3cc7-4d94-84e9-2733c63df47c',
    symbol: 'XYO',
    url: 'https://xyo.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2765.png',
  },
  {
    label: 'Fetch.ai',
    id: '0a4343a6-ea0c-4c80-8f05-4a9bea326b38',
    symbol: 'FET',
    url: 'https://fetch.ai/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3773.png',
  },
  {
    label: 'WazirX',
    id: '4763841b-d091-473c-9f2e-136478f467f2',
    symbol: 'WRX',
    url: 'https://wazirx.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5161.png',
  },
  {
    label: 'Dent',
    id: 'd313059b-884c-40a7-b6b6-df68a1cbd346',
    symbol: 'DENT',
    url: 'https://www.dentcoin.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1886.png',
  },
  {
    label: 'Keep3rV1',
    id: '12b9cb58-269f-4bea-b5a3-43f54cb7e9d4',
    symbol: 'KP3R',
    url: 'https://keep3r.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7535.png',
  },
  {
    label: 'Swipe',
    id: 'db133240-22fd-4018-bcd3-60c819d8e38d',
    symbol: 'SXP',
    url: 'https://swipe.io/token',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4279.png',
  },
  {
    label: 'Power Ledger',
    id: 'c22b2ec3-6dc7-40a3-82b7-e159544e481b',
    symbol: 'POWR',
    url: 'https://powerledger.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2132.png',
  },
  {
    label: 'Ultra',
    id: '21432991-8ee1-4400-b3b9-851eda9ec58d',
    symbol: 'UOS',
    url: 'https://ultra.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4189.png',
  },
  {
    label: 'Moonriver',
    id: 'cc460e63-548b-47e4-a8ac-cdc758d8baaa',
    symbol: 'MOVR',
    url: 'https://moonbeam.network/networks/moonriver/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9285.png',
  },
  {
    label: 'Dusk Network',
    id: '161422fa-871c-40c9-a6fa-ec14666b24b1',
    symbol: 'DUSK',
    url: 'https://www.dusk.network',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9285.png',
  },
  {
    label: 'Gemini Dollar',
    id: '60e7d532-a3b6-4e05-a695-25600875fe0e',
    symbol: 'GUSD',
    url: 'https://gemini.com/dollar/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3306.png',
  },
  {
    label: 'CEEK VR',
    id: '1616d095-0e55-4f69-89b4-7de3ecac0540',
    symbol: 'CEEK',
    url: 'https://www.ceek.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2856.png',
  },
  {
    label: 'Reserve Rights',
    id: '6d079bf8-36e1-4e59-a798-3de621511872',
    symbol: 'RSR',
    url: 'https://reserve.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2856.png',
  },
  {
    label: 'COTI',
    id: '8a13d7ee-b2ea-445a-a740-1c4d8ee24138',
    symbol: 'COTI',
    url: 'https://coti.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3992.png',
  },
  {
    label: 'Request Network',
    id: '33d13b9e-0d1b-4c87-81b2-900a0a96ab52',
    symbol: 'REQ',
    url: 'https://request.network/#/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2071.png',
  },
  {
    label: 'Cartesi',
    id: 'cb9d8bad-f908-4ec3-a876-695b361dc978',
    symbol: 'CTSI',
    url: 'https://cartesi.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5444.png',
  },
  {
    label: 'Mdex',
    id: '7119dfaf-1495-4413-992a-1411001c4451',
    symbol: 'MDX',
    url: 'https://mdex.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8335.png',
  },
  {
    label: 'OriginTrail',
    id: 'f5715302-2fcd-4cb9-bb0b-e386ec08dec5',
    symbol: 'TRAC',
    url: 'https://origintrail.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2467.png',
  },
  {
    label: 'My Neighbor Alice',
    id: 'b726c866-4f46-4275-abb3-09542743fe50',
    symbol: 'ALICE',
    url: 'https://www.myneighboralice.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8766.png',
  },
  {
    label: 'Lisk',
    id: 'd8a7d8f0-d8cf-4e61-a7ec-321ae8b48ee5',
    symbol: 'LSK',
    url: 'https://lisk.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1214.png',
  },
  {
    label: 'Chrono.tech',
    id: '42d99ec5-ccdd-4d96-8b88-20f103f9011b',
    symbol: 'TIME',
    url: 'https://chronobank.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1556.png',
  },
  {
    label: 'MediBloc',
    id: '9fd517d4-8939-4a3a-8c28-259e0bd4c5a2',
    symbol: 'MED',
    url: 'https://medibloc.org/en/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2303.png',
  },
  {
    label: 'Injective Protocol',
    id: '79bc95fc-ea80-4db4-addc-fee813963289',
    symbol: 'INJ',
    url: 'https://injectiveprotocol.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7226.png',
  },
  {
    label: 'Propy',
    id: 'a0be30e0-ed32-436f-895d-2cc688158aff',
    symbol: 'PRO',
    url: 'https://propy.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1974.png',
  },
  {
    label: 'Ontology Gas',
    id: '9da2f4a2-fb77-441a-9aea-1e4c0cc5285f',
    symbol: 'ONG',
    url: 'https://ont.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3217.png',
  },
  {
    label: 'Constellation',
    id: '2b873084-3e7c-499a-972c-29c673380aa0',
    symbol: 'DAG',
    url: 'https://www.constellationlabs.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2868.png',
  },
  {
    label: 'Biconomy',
    id: '928fb1b4-0b2b-4196-a066-0b7f003b0f9f',
    symbol: 'BICO',
    url: 'https://www.biconomy.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9543.png',
  },
  {
    label: 'Aragon',
    id: 'e73cd8bd-20dc-4ac9-bee8-638f0b4d5bd7',
    symbol: 'ANT',
    url: 'https://aragon.one/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1680.png',
  },
  {
    label: 'Energy Web Token',
    id: 'be2bcf5e-584d-40d6-9c79-69ab17a0c08a',
    symbol: 'EWT',
    url: 'https://www.energyweb.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5268.png',
  },
  {
    label: 'Alpha Finance Lab',
    id: '71b762e0-91e5-451c-8894-a43305eceb18',
    symbol: 'ALPHA',
    url: 'https://alphafinance.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7232.png',
  },
  {
    label: 'Bitcoin Standard Hashrate Token',
    id: '7da94d5a-7282-4135-902d-14f63ea060a4',
    symbol: 'BTCST',
    url: 'https://www.btcst.finance/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8891.png',
  },
  {
    label: 'Reef Finance',
    id: '2174b8bd-339f-4c56-9c45-4baf5e9ddfc7',
    symbol: 'REEF',
    url: 'https://reef.finance/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6951.png',
  },
  {
    label: 'VeChain Energy',
    id: '0f7af8c2-8020-4bfa-a3d3-ffe43991180e',
    symbol: 'VTHO',
    url: 'https://www.vechain.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3012.png',
  },
  {
    label: 'Orbs',
    id: 'dec30bbc-60c5-4fe2-939d-4772dc680317',
    symbol: 'ORBS',
    url: 'https://www.orbs.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3835.png',
  },
  {
    label: 'Machine Xchange Coin',
    id: '7ed36b52-8f3b-406a-8568-d228078a164d',
    symbol: 'MXC',
    url: 'https://www.mxc.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3628.png',
  },
  {
    label: 'Status Network',
    id: 'af85a038-7506-44cf-9a72-6cf073b1919a',
    symbol: 'SNT',
    url: 'http://status.im/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1759.png',
  },
  {
    label: 'Bitcoin Diamond',
    id: '1f44af86-9048-486e-a05f-7f67f24e81d1',
    symbol: 'BCD',
    url: 'https://www.bitcoindiamond.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2222.png',
  },
  {
    label: 'aelf',
    id: 'b98e91fe-8ad3-4679-82b7-d70f67d74f2f',
    symbol: 'ELF',
    url: 'http://aelf.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2299.png',
  },
  {
    label: 'Orchid',
    id: '9b12ad97-4d9b-4669-b656-1bbd50af463e',
    symbol: 'OXT',
    url: 'https://orchid.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5026.png',
  },
  {
    label: 'Ardor',
    id: 'ade9474c-d291-4c19-9a37-e7401c8ee7bc',
    symbol: 'ARDR',
    url: 'https://www.ardorplatform.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1320.png',
  },
  {
    label: 'Verge',
    id: '2b83b17b-899f-41f0-bb93-79d0f215291f',
    symbol: 'XVG',
    url: 'http://vergecurrency.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/693.png',
  },
  {
    label: 'SUN',
    id: '391dac7a-b197-499b-990a-bb0b6ee3e37e',
    symbol: 'SUN',
    url: 'https://sun.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10529.png',
  },
  {
    label: 'Phantasma',
    id: '1741a6fe-6a0d-4b24-8036-62bb279f6ed5',
    symbol: 'SOUL',
    url: 'https://phantasma.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2827.png',
  },
  {
    label: 'HEX',
    id: 'e84b168e-94ff-48c2-a94e-e829c443a9f7',
    symbol: 'HEX',
    url: 'https://hex.win/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5015.png',
  },
  {
    label: 'Lido Staked Ether',
    id: '83973392-6901-499f-8e52-be234d3c246c',
    symbol: 'STETH',
    url: 'https://www.lido.fi/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8085.png',
  },
  {
    label: 'Frax Finance',
    id: 'ce23042a-5f6b-419b-8cd8-a072ff6996d1',
    symbol: 'FRAX',
    url: 'https://frax.finance/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png',
  },

  {
    label: 'Huobi BTC',
    id: '1dd223ad-e2df-43ec-a65c-ad8dfdd712f7',
    symbol: 'HBTC',
    url: 'https://www.htokens.finance/en-us/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6941.png',
  },
  {
    label: 'ECOMI',
    id: 'c402b0ab-1b96-492e-ac1c-5e2d1723b59c',
    symbol: 'OMI',
    url: 'https://www.ecomi.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6941.png',
  },
  {
    label: 'SafeMoon',
    id: 'b21d0590-59ee-4dea-82db-f0a5d99e197b',
    symbol: 'SAFEMOON',
    url: 'https://safemoon.net/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8757.png',
  },
  {
    label: 'Symbol',
    id: '6067b598-b26c-4e3e-8c2a-010a1c7d2b1c',
    symbol: 'XYM',
    url: 'https://symbolplatform.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8677.png',
  },
  {
    label: 'Alt.Estate',
    id: 'afb1b015-c362-43cf-be2c-7d3eab6c3451',
    symbol: 'ALT',
    url: 'https://alt.estate/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10897.png',
  },
  {
    label: 'Spell Token',
    id: 'f96d1628-6e6e-4cc9-a9d3-c09db32c9b71',
    symbol: 'SPELL',
    url: 'https://abracadabra.money/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11289.png',
  },
  {
    label: 'TrustNote',
    id: 'd7c90f24-6b7f-442a-91ee-63302261a382',
    symbol: 'TTT',
    url: 'https://trustnote.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5514.png',
  },
  {
    label: 'Rally',
    id: '1dd2b313-5cd0-4828-9dc5-e2148ebd1eda',
    symbol: 'RLY',
    url: 'https://rally.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8075.png',
  },
  {
    label: 'Render Token',
    id: '11f5aa3a-4a6d-4385-ab60-5941f4436979',
    symbol: 'RNDR',
    url: 'https://rendertoken.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5690.png',
  },
  {
    label: 'WhiteCoin',
    id: 'dea416ee-c42b-4103-8185-c768ea9013c9',
    symbol: 'XWC',
    url: 'http://whitecoin.info',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/268.png',
  },
  {
    label: 'Huobi HUSD',
    id: 'ff4a14f6-2111-4186-ab0d-615c2b1fdb82',
    symbol: 'HUSD',
    url: 'http://www.stcoins.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4779.png',
  },
  {
    label: 'Illuvium',
    id: '981a19ec-d34f-4d26-98c2-bc12f3326c96',
    symbol: 'ILV',
    url: 'https://illuvium.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8719.png',
  },
  {
    label: 'Decentralized Social',
    id: '458e089f-6887-4ab2-9341-fc070d4a0b36',
    symbol: 'DESO',
    url: 'https://bitclout.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10442.png',
  },
  {
    label: 'Ethereum Name Service',
    id: '830658c4-cf04-4311-87e5-9997c57b81bb',
    symbol: 'ENS',
    url: 'https://ens.domains/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13855.png',
  },
  {
    label: 'Anchor Protocol',
    id: '52f12a62-9ee1-46df-abf4-52c96a725c13',
    symbol: 'ANC',
    url: 'https://anchorprotocol.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8857.png',
  },
  {
    label: 'Hero',
    id: 'c3cf2415-f8c5-4a71-b6e7-105056e99d28',
    symbol: 'HERO',
    url: 'https://herotoken.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10778.png',
  },
  {
    label: 'Tribe Governance Token',
    id: '96d11ad5-8a85-4379-96ce-4f9da059cb71',
    symbol: 'TRIBE',
    url: 'https://fei.money/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9025.png',
  },
  {
    label: 'Frax Share',
    id: '8de169ae-eb87-4f09-b529-bda86d1b06d6',
    symbol: 'FXS',
    url: 'https://frax.finance/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6953.png',
  },
  {
    label: 'Rocket Pool',
    id: '98e042fb-7ff8-4c48-9c3d-7f19cebd2bc2',
    symbol: 'RPL',
    url: 'https://www.rocketpool.net/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2943.png',
  },
  {
    label: 'Persistence',
    id: '0865a42d-782e-449a-8356-05d31cfe5923',
    symbol: 'XPRT',
    url: 'https://persistence.one/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7281.png',
  },
  {
    label: 'Keep Network',
    id: '951fef4a-d8ce-4011-936c-01756e73d08f',
    symbol: 'KEEP',
    url: 'https://keep.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7281.png',
  },
  {
    label: 'Kyber Network',
    id: 'b85832ae-695f-4ec0-b7a8-43480b4561a3',
    symbol: 'KNC',
    url: 'https://etherscan.io/token/KyberNetwork',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9444.png',
  },
  {
    label: 'Rari Governance Token',
    id: 'b974a57c-7f74-4c93-b7fb-e2b328b88048',
    symbol: 'RGT',
    url: 'https://www.rari.capital/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7486.png',
  },
  {
    label: 'JasmyCoin',
    id: 'f79f9ae5-8a10-47d3-870c-13258af0382c',
    symbol: 'JASMY',
    url: 'https://www.jasmy.co.jp/en.html',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8425.png',
  },
  {
    label: 'PlayDapp',
    id: '03ed8ae4-6203-4aaa-8efb-1daf8620843c',
    symbol: 'PLA',
    url: 'https://playdapp.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7461.png',
  },
  {
    label: 'Yield Guild Games',
    id: '6ddf0e92-1de1-4ad3-8957-c1eb433c2657',
    symbol: 'YGG',
    url: 'https://yieldguild.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10688.png',
  },
  {
    label: 'Bloktopia',
    id: '38a48390-2a13-4e42-a335-17184e7df40e',
    symbol: 'BLOK',
    url: 'https://www.bloktopia.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11206.png',
  },
  {
    label: 'Braintrust ',
    id: '770f94c7-674c-4036-99c4-f1fb14b6f15a',
    symbol: 'BTRST',
    url: 'https://www.usebraintrust.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11584.png',
  },
  {
    label: 'Chia Network',
    id: '19b9b4bc-caa0-438a-9e2a-1c079fe8733e',
    symbol: 'XCH',
    url: 'https://www.chia.net/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9258.png',
  },
  {
    label: 'Akash Network',
    id: 'ab909810-4301-406a-8bbe-9fd0c789dfa0',
    symbol: 'AKT',
    url: 'https://akash.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7431.png',
  },
  {
    label: 'Pundi X',
    id: '33925013-bf9e-4550-afac-2cac1b0a35ae',
    symbol: 'PUNDIX',
    url: 'https://pundix.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9040.png',
  },
  {
    label: 'Tether Gold',
    id: '1490fca6-29f8-416a-835b-e9d000f26fd0',
    symbol: 'XAUT',
    url: 'https://tether.to/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5176.png',
  },
  {
    label: 'HedgeTrade',
    id: '3dac54cd-937c-4a9e-931c-fdb6a8a529b2',
    symbol: 'HEDG',
    url: 'https://hedgetrade.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3662.png',
  },
  {
    label: 'Alchemix',
    id: 'c846dc0f-64d6-47d8-9328-886aae8043eb',
    symbol: 'ALCX',
    url: 'https://alchemix.fi/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8613.png',
  },
  {
    label: 'Mask Network',
    id: '0cd344da-636c-42ef-af9b-1797f394f3ed',
    symbol: 'MASK',
    url: 'https://mask.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8536.png',
  },
  {
    label: 'API3',
    id: '9ee63de2-e1fc-42fa-aad5-d8ca57152bd7',
    symbol: 'API3',
    url: 'https://api3.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7737.png',
  },
  {
    label: 'Veritaseum',
    id: '00e0f88a-939a-4d6a-9344-acfc290bf61a',
    symbol: 'VERI',
    url: 'http://veritas.veritaseum.com/index.php',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1710.png',
  },
  {
    label: 'SURETY.AI',
    id: '2d5f6d1f-5e4e-4564-bee6-a3bed88ba519',
    symbol: 'SURE',
    url: 'https://ico.surety.ai/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5113.png',
  },
  {
    label: 'Prometeus',
    id: '19990154-9b50-4ea5-9f13-871c77cf0309',
    symbol: 'PROM',
    url: 'https://prometeus.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4120.png',
  },
  {
    label: 'Alchemy Pay',
    id: '4dd3774a-35e1-4108-9db3-a1a1d7391806',
    symbol: 'ACH',
    url: 'https://www.alchemytech.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6958.png',
  },
  {
    label: 'Divi',
    id: 'b549359b-cd26-4d91-8402-5b56e366ffa0',
    symbol: 'DIVI',
    url: 'https://www.diviproject.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3441.png',
  },
  {
    label: 'Humanscape',
    id: '1d727840-f88f-4bdf-a4ad-2d512466ada4',
    symbol: 'HUM',
    url: 'https://humanscape.io/en/index.html',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3600.png',
  },
  {
    label: 'Civic',
    id: '1ec3976a-0828-4d4f-a868-556853f9ca89',
    symbol: 'CVC',
    url: 'https://www.civic.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1816.png',
  },
  {
    label: 'MaidSafeCoin',
    id: 'c9c34bb1-e480-4882-ba41-e14b288bf5d3',
    symbol: 'MAID',
    url: 'http://www.safecoin.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/291.png',
  },
  {
    label: 'iExec RLC',
    id: '340214f8-262b-4323-a766-a0f3b129c229',
    symbol: 'RLC',
    url: 'http://iex.ec/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1637.png',
  },
  {
    label: 'Orion Protocol',
    id: '9eef381a-f4f5-48fb-adc8-39f9263d216e',
    symbol: 'ORN',
    url: 'https://www.orionprotocol.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5631.png',
  },
  {
    label: 'Conflux Network',
    id: '7810e69a-f407-4a21-a1e2-d75b47284591',
    symbol: 'CFX',
    url: 'https://confluxnetwork.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7334.png',
  },
  {
    label: 'Polkastarter',
    id: '1fbd8acf-292e-4f73-afae-51ba2b0d4c6f',
    symbol: 'POLS',
    url: 'https://www.polkastarter.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7208.png',
  },
  {
    label: 'JOE',
    id: 'f72c4e96-888d-4743-bfe8-4d9dd08741e6',
    symbol: 'JOE',
    url: 'https://www.traderjoexyz.com/#/home',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11396.png',
  },
  {
    label: 'Augur',
    id: '02058ead-9484-4a3e-8231-d4be34dd8514',
    symbol: 'REP',
    url: 'http://www.augur.net/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11396.png',
  },
  {
    label: 'Unibright',
    id: 'fd2660b7-a1de-445d-872e-6b95e965578b',
    symbol: 'UBT',
    url: 'https://unibright.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11396.png',
  },
  {
    label: 'NKN',
    id: '05e8beba-dfdd-4ec8-b733-1a7527e23b43',
    symbol: 'NKN',
    url: 'https://nkn.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2780.png',
  },
  {
    label: 'Radicle ',
    id: 'b06d9b5f-7cd8-4a11-b75b-0693ec904cd2',
    symbol: 'RAD',
    url: 'https://radicle.xyz/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6843.png',
  },
  {
    label: 'Decentral Games',
    id: '63d80028-5619-491d-921d-825c77d936fc',
    symbol: 'DG',
    url: 'https://decentral.games/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15478.png',
  },
  {
    label: 'Centrality',
    id: '8646e84c-ebdb-41e9-ba6c-9093e8809487',
    symbol: 'CENNZ',
    url: 'https://www.centrality.ai/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2585.png',
  },
  {
    label: 'Badger DAO',
    id: '09f43775-b08f-497d-9fc0-931550db5ac0',
    symbol: 'BADGER',
    url: 'https://app.badger.finance/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7859.png',
  },
  {
    label: 'ABBC Coin',
    id: '8f781ca6-e5a8-42a9-acaf-4bff6e415f4a',
    symbol: 'ABBC',
    url: 'https://www.abbcfoundation.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3437.png',
  },
  {
    label: 'StormX',
    id: 'e2f53727-d1ac-42ca-815a-fa799b36ab69',
    symbol: 'STMX',
    url: 'https://stormx.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2297.png',
  },
  {
    label: 'Numeraire',
    id: 'ce11fcf4-3051-4127-b173-76e4e0373a26',
    symbol: 'NMR',
    url: 'https://numer.ai/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1732.png',
  },
  {
    label: 'KardiaChain',
    id: 'a2218ee3-9260-44a0-a84b-067512107b99',
    symbol: 'KAI',
    url: 'https://kardiachain.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5453.png',
  },
  {
    label: 'Hxro',
    id: 'df0776b2-92ee-4f18-be01-3d4b518cd56e',
    symbol: 'HXRO',
    url: 'https://www.hxro.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3748.png',
  },
  {
    label: 'Origin Protocol',
    id: 'd6e16143-af78-4bd8-b223-3371c7cdbe48',
    symbol: 'OGN',
    url: 'https://www.originprotocol.com/en/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5117.png',
  },
  {
    label: 'Pirate Chain',
    id: '76a5ca8b-d8ab-4040-b11f-1a9279e766a2',
    symbol: 'ARRR',
    url: 'https://pirate.black/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3951.png',
  },

  {
    label: 'Aavegotchi',
    id: 'c42b9d3f-0006-4b8f-b86b-dba12fb7578c',
    symbol: 'GHST',
    url: 'https://www.aavegotchi.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7046.png',
  },
  {
    label: 'Band Protocol',
    id: 'daf9f645-b832-4859-bdf4-2a833571ab90',
    symbol: 'BAND',
    url: 'https://bandprotocol.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4679.png',
  },
  {
    label: 'e-Radix',
    id: '1913f793-3cc9-43af-b69a-e0470234daf1',
    symbol: 'EXRD',
    url: 'https://www.radixdlt.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7692.png',
  },
  {
    label: 'Crypto20',
    id: '8cf99387-0626-4bcb-94fc-a97abd2314a5',
    symbol: 'C20',
    url: 'https://crypto20.com/en/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2444.png',
  },
  {
    label: 'SingularityNET',
    id: '7a13b2f9-02ac-498e-80e3-fc56bdf96d60',
    symbol: 'AGIX',
    url: 'https://singularitynet.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2424.png',
  },
  {
    label: 'Electroneum',
    id: '9673637a-b5df-49bc-8596-df6a02ed646d',
    symbol: 'ETN',
    url: 'http://electroneum.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2137.png',
  },
  {
    label: 'BakerySwap',
    id: '42ec4680-a2d6-4bdc-be9f-9d2a28add013',
    symbol: 'BAKE',
    url: 'https://www.bakeryswap.org/#/home',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7064.png',
  },
  {
    label: 'Circuits Of Value',
    id: 'f0e63a21-b4bb-4f16-9353-70ea416a928c',
    symbol: 'COVAL',
    url: 'http://cov.al/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/788.png',
  },
  {
    label: 'Steem',
    id: '2a844bd5-fdb2-409b-8c16-5ccdbe01753d',
    symbol: 'STEEM',
    url: 'https://steemit.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1230.png',
  },
  {
    label: 'RIF Token',
    id: '72963178-643f-414b-ba90-c757a241fb70',
    symbol: 'RIF',
    url: 'https://www.rifos.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3701.png',
  },
  {
    label: 'Elastos',
    id: '366a864c-926a-4a23-98d7-923c4806ea6a',
    symbol: 'ELA',
    url: 'https://www.elastos.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2492.png',
  },
  {
    label: 'STASIS EURS',
    id: '923395ba-b940-49ee-a5c1-542c787e710f',
    symbol: 'EURS',
    url: 'https://stasis.net/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2989.png',
  },
  {
    label: 'Sovereign Coin',
    id: '2b063b96-e6eb-433b-b9b0-6f46b6d3d2fc',
    symbol: 'SOV',
    url: 'https://sovcore.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2989.png',
  },
  {
    label: 'UTrust',
    id: 'e4c543af-45d3-40dd-9717-9ada0863909d',
    symbol: 'UTK',
    url: 'https://utrust.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2320.png',
  },
  {
    label: 'Ampleforth',
    id: '551cdbbe-2a97-4af8-b6bc-3254210ed021',
    symbol: 'AMPL',
    url: 'https://www.ampleforth.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4056.png',
  },
  {
    label: 'FunToken',
    id: '35420351-29f7-42b6-a77f-1dbdbf517443',
    symbol: 'FUN',
    url: 'https://funtoken.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1757.png',
  },
  {
    label: 'PolySwarm',
    id: '809fe91d-c76e-4096-bcdc-7a0e5020c6df',
    symbol: 'NCT',
    url: 'https://polyswarm.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2630.png',
  },
  {
    label: 'Ark',
    id: 'ad08ebcd-cf2a-474f-95a5-a626e523462c',
    symbol: 'ARK',
    url: 'https://ark.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1586.png',
  },
  {
    label: 'Metadium',
    id: '05e396e0-c9e4-42bc-ba52-012e77c7f737',
    symbol: 'META',
    url: 'https://www.metadium.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3418.png',
  },
  {
    label: 'Mass Vehicle Ledger',
    id: '3c7df232-5f09-4a64-9398-bebce5022a7f',
    symbol: 'MVL',
    url: 'https://mvlchain.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2982.png',
  },
  {
    label: 'Aleph.im',
    id: '42c4839e-9e3f-4e90-8967-28743aeccf5a',
    symbol: 'ALEPH',
    url: 'https://aleph.im/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2982.png',
  },
  {
    label: 'Clover Finance',
    id: '3e9de5dc-f0eb-49be-b87b-0c5cb9de7c2b',
    symbol: 'CLV',
    url: 'https://clover.finance/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8384.png',
  },
  {
    label: 'Star Atlas',
    id: '826742e7-b5c5-48ac-a529-05c08edc3628',
    symbol: 'ATLAS',
    url: 'https://staratlas.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11212.png',
  },
  {
    label: 'BitcoinFile',
    id: 'e585c3f2-ef5f-4591-a588-de72770476e4',
    symbol: 'BIFI',
    url: 'https://www.bitcoinfile.org',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7311.png',
  },
  {
    label: 'Sologenic',
    id: '9abafd72-baa5-4d34-984d-e441a6da62cb',
    symbol: 'SOLO',
    url: 'https://www.sologenic.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5279.png',
  },
  {
    label: 'TomoChain',
    id: '5a55edf6-feca-4111-a972-3a5709e3dd87',
    symbol: 'TOMO',
    url: 'https://tomocoin.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2570.png',
  },
  {
    label: 'Gitcoin',
    id: 'f383a66f-ecc3-4df7-b57c-d3802883876b',
    symbol: 'GTC',
    url: 'https://gitcoin.co/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10052.png',
  },
  {
    label: 'Alien Worlds',
    id: 'dff33676-abcb-4cac-a9ec-0af10d5a46ae',
    symbol: 'TLM',
    url: 'https://alienworlds.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9119.png',
  },
  {
    label: 'TrueFi',
    id: '9299cafb-7f8b-4ee5-96db-fa1ac2f4b21d',
    symbol: 'TRU',
    url: 'https://truefi.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7725.png',
  },
  {
    label: 'Mirror Protocol',
    id: '9ed7ba36-0b7b-41d9-8e02-ee1359b0a970',
    symbol: 'MIR',
    url: 'https://mirror.finance/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7857.png',
  },
  {
    label: 'Enzyme Finance',
    id: '0bbfaad8-2cb6-4eed-905d-92148ba21d73',
    symbol: 'MLN',
    url: 'https://enzyme.finance/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1552.png',
  },
  {
    label: 'Mines of Dalarnia',
    id: 'f2afd9d9-2db5-4cc5-b8b9-8031536ee540',
    symbol: 'DAR',
    url: 'https://www.minesofdalarnia.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11374.png',
  },
  {
    label: 'Venus',
    id: '117fd982-c974-4c1b-8a25-60967fe4d223',
    symbol: 'XVS',
    url: 'https://venus.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7288.png',
  },
  {
    label: 'sUSD',
    id: '94c27d46-0e42-44dc-9acb-f7512e949243',
    symbol: 'SUSD',
    url: 'https://www.synthetix.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2927.png',
  },
  {
    label: 'DeFiPulse Index',
    id: 'a23cd92b-f0b3-4c10-935f-ffdcaa311e48',
    symbol: 'DPI',
    url: 'https://www.tokensets.com/portfolio/dpi',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7055.png',
  },
  {
    label: 'TrustSwap',
    id: '64c2ea9d-79ee-46f6-9b56-778d9b9a5082',
    symbol: 'SWAP',
    url: 'https://trustswap.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5829.png',
  },
  {
    label: 'STP Network',
    id: '797f746f-d17d-4e58-9819-9de1e0d62342',
    symbol: 'STPT',
    url: 'https://stp.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4006.png',
  },
  {
    label: 'Ergo',
    id: '598a54cd-98dc-47a5-9224-ab3a6789f9a3',
    symbol: 'ERG',
    url: 'https://ergoplatform.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1762.png',
  },
  {
    label: 'Verasity',
    id: 'bd2aee2b-9ff8-4018-b65c-3efbc05d63ab',
    symbol: 'VRA',
    url: 'https://www.verasity.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3816.png',
  },
  {
    label: 'Uquid Coin',
    id: 'abe8a15e-d98d-404f-a8bd-eaf82e476834',
    symbol: 'UQC',
    url: 'https://uquidcoin.com/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2273.png',
  },
  {
    label: 'Ribbon Finance',
    id: 'f9e9e9ee-fae6-4a51-a56f-9398c73d0f58',
    symbol: 'RBN',
    url: 'https://www.ribbon.finance/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12387.png',
  },
  {
    label: 'Handshake',
    id: '116efa88-9d29-4c64-b316-6a2e81e6903a',
    symbol: 'HNS',
    url: 'https://handshake.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5221.png',
  },
  {
    label: 'TitanSwap',
    id: '94e0a131-c4fa-437c-be4b-777050e53c7a',
    symbol: 'TITAN',
    url: 'https://titanswap.org/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7206.png',
  },
  {
    label: 'bZx Protocol',
    id: 'dcc82b53-6973-4ff7-a794-1f027fc8eb91',
    symbol: 'BZRX',
    url: 'https://bzx.network/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5810.png',
  },
  {
    label: 'QuarkChain',
    id: '48192178-5d99-412d-a071-cb371415df44',
    symbol: 'QKC',
    url: 'https://quarkchain.io/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2840.png',
  },
  {
    label: 'Hyperion',
    id: '04fefa3d-ff02-4c05-8da1-05d69be6ecca',
    symbol: 'HYN',
    url: 'https://www.hyn.space/',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3695.png',
  },
];
