export const curData = [
  { id: 'USD', country: 'United States Dollar' },
  { id: 'AED', country: 'UAE Dirham' },
  { id: 'AFN', country: 'Afghan Afghani' },
  { id: 'ALL', country: 'Albanian Lek' },
  { id: 'AMD', country: 'Armenian Dram' },
  { id: 'ANG', country: 'Netherlands Antillian Guilder' },
  { id: 'AOA', country: 'Angolan Kwanza' },
  { id: 'ARS', country: 'Argentine Peso' },
  { id: 'AUD', country: 'Australian Dollar' },
  { id: 'AWG', country: 'Aruban Florin' },
  { id: 'AZN', country: 'Azerbaijani Manat' },
  { id: 'BAM', country: 'Bosnia and Herzegovina Mark' },
  { id: 'BBD', country: 'Barbados Dollar' },
  { id: 'BDT', country: 'Bangladeshi Taka' },
  { id: 'BGN', country: 'Bulgarian Lev' },
  { id: 'BHD', country: 'Bahraini Dinar' },
  { id: 'BIF', country: 'Burundian Franc' },
  { id: 'BMD', country: 'Bermudian Dollar' },
  { id: 'BND', country: 'Brunei Dollar' },
  { id: 'BOB', country: 'Bolivian Boliviano' },
  { id: 'BRL', country: 'Brazilian Real' },
  { id: 'BSD', country: 'Bahamian Dollar' },
  { id: 'BTN', country: 'Bhutanese Ngultrum' },
  { id: 'BWP', country: 'Botswana Pula' },
  { id: 'BYN', country: 'Belarusian Ruble' },
  { id: 'BZD', country: 'Belize Dollar' },
  { id: 'CAD', country: 'Canadian Dollar' },
  { id: 'CDF', country: 'Congolese Franc' },
  { id: 'CHF', country: 'Swiss Franc' },
  { id: 'CLP', country: 'Chilean Peso' },
  { id: 'CNY', country: 'Chinese Renminbi' },
  { id: 'COP', country: 'Colombian Peso' },
  { id: 'CRC', country: 'Costa Rican Colon' },
  { id: 'CUP', country: 'Cuban Peso' },
  { id: 'CVE', country: 'Cape Verdean Escudo' },
  { id: 'CZK', country: 'Czech Koruna' },
  { id: 'DJF', country: 'Djiboutian Franc' },
  { id: 'DKK', country: 'Danish Krone' },
  { id: 'DOP', country: 'Dominican Peso' },
  { id: 'DZD', country: 'Algerian Dinar' },
  { id: 'EGP', country: 'Egyptian Pound' },
  { id: 'ERN', country: 'Eritrean Nakfa' },
  { id: 'ETB', country: 'Ethiopian Birr' },
  { id: 'EUR', country: 'Euro' },
  { id: 'FJD', country: 'Fiji Dollar' },
  { id: 'FKP', country: 'Falkland Islands Pound' },
  { id: 'FOK', country: 'Faroese Króna' },
  { id: 'GBP', country: 'Pound Sterling' },
  { id: 'GEL', country: 'Georgian Lari' },
  { id: 'GGP', country: 'Guernsey Pound' },
  { id: 'GHS', country: 'Ghanaian Cedi' },
  { id: 'GIP', country: 'Gibraltar Pound' },
  { id: 'GMD', country: 'Gambian Dalasi' },
  { id: 'GNF', country: 'Guinean Franc' },
  { id: 'GTQ', country: 'Guatemalan Quetzal' },
  { id: 'GYD', country: 'Guyanese Dollar' },
  { id: 'HKD', country: 'Hong Kong Dollar' },
  { id: 'HNL', country: 'Honduran Lempira' },
  { id: 'HRK', country: 'Croatian Kuna' },
  { id: 'HTG', country: 'Haitian Gourde' },
  { id: 'HUF', country: 'Hungarian Forint' },
  { id: 'IDR', country: 'Indonesian Rupiah' },
  { id: 'ILS', country: 'Israeli New Shekel' },
  { id: 'IMP', country: 'Manx Pound' },
  { id: 'INR', country: 'Indian Rupee' },
  { id: 'IQD', country: 'Iraqi Dinar' },
  { id: 'IRR', country: 'Iranian Rial' },
  { id: 'ISK', country: 'Icelandic Króna' },
  { id: 'JEP', country: 'Jersey Pound' },
  { id: 'JMD', country: 'Jamaican Dollar' },
  { id: 'JOD', country: 'Jordanian Dinar' },
  { id: 'JPY', country: 'Japanese Yen' },
  { id: 'KES', country: 'Kenyan Shilling' },
  { id: 'KGS', country: 'Kyrgyzstani Som' },
  { id: 'KHR', country: 'Cambodian Riel' },
  { id: 'KID', country: 'Kiribati Dollar' },
  { id: 'KMF', country: 'Comorian Franc' },
  { id: 'KRW', country: 'South Korean Won' },
  { id: 'KWD', country: 'Kuwaiti Dinar' },
  { id: 'KYD', country: 'Cayman Islands Dollar' },
  { id: 'KZT', country: 'Kazakhstani Tenge' },
  { id: 'LAK', country: 'Lao Kip' },
  { id: 'LBP', country: 'Lebanese Pound' },
  { id: 'LKR', country: 'Sri Lanka Rupee' },
  { id: 'LRD', country: 'Liberian Dollar' },
  { id: 'LSL', country: 'Lesotho Loti' },
  { id: 'LYD', country: 'Libyan Dinar' },
  { id: 'MAD', country: 'Moroccan Dirham' },
  { id: 'MDL', country: 'Moldovan Leu' },
  { id: 'MGA', country: 'Malagasy Ariary' },
  { id: 'MKD', country: 'Macedonian Denar' },
  { id: 'MMK', country: 'Burmese Kyat' },
  { id: 'MNT', country: 'Mongolian Tögrög' },
  { id: 'MOP', country: 'Macanese Pataca' },
  { id: 'MRU', country: 'Mauritanian Ouguiya' },
  { id: 'MUR', country: 'Mauritian Rupee' },
  { id: 'MVR', country: 'Maldivian Rufiyaa' },
  { id: 'MWK', country: 'Malawian Kwacha' },
  { id: 'MXN', country: 'Mexican Peso' },
  { id: 'MYR', country: 'Malaysian Ringgit' },
  { id: 'MZN', country: 'Mozambican Metical' },
  { id: 'NAD', country: 'Namibian Dollar' },
  { id: 'NGN', country: 'Nigerian Naira' },
  { id: 'NIO', country: 'Nicaraguan Córdoba' },
  { id: 'NOK', country: 'Norwegian Krone' },
  { id: 'NPR', country: 'Nepalese Rupee' },
  { id: 'NZD', country: 'New Zealand Dollar' },
  { id: 'OMR', country: 'Omani Rial' },
  { id: 'PAB', country: 'Panamanian Balboa' },
  { id: 'PEN', country: 'Peruvian Sol' },
  { id: 'PGK', country: 'Papua New Guinean Kina' },
  { id: 'PHP', country: 'Philippine Peso' },
  { id: 'PKR', country: 'Pakistani Rupee' },
  { id: 'PLN', country: 'Polish Złoty' },
  { id: 'PYG', country: 'Paraguayan Guaraní' },
  { id: 'QAR', country: 'Qatari Riyal' },
  { id: 'RON', country: 'Romanian Leu' },
  { id: 'RSD', country: 'Serbian Dinar' },
  { id: 'RUB', country: 'Russian Ruble' },
  { id: 'RWF', country: 'Rwandan Franc' },
  { id: 'SAR', country: 'Saudi Riyal' },
  { id: 'SBD', country: 'Solomon Islands Dollar' },
  { id: 'SCR', country: 'Seychellois Rupee' },
  { id: 'SDG', country: 'Sudanese Pound' },
  { id: 'SEK', country: 'Swedish Krona' },
  { id: 'SGD', country: 'Singapore Dollar' },
  { id: 'SHP', country: 'Saint Helena Pound' },
  { id: 'SLL', country: 'Sierra Leonean Leone' },
  { id: 'SOS', country: 'Somali Shilling' },
  { id: 'SRD', country: 'Surinamese Dollar' },
  { id: 'SSP', country: 'South Sudanese Pound' },
  { id: 'STN', country: 'São Tomé and Príncipe Dobra' },
  { id: 'SYP', country: 'Syrian Pound' },
  { id: 'SZL', country: 'Eswatini Lilangeni' },
  { id: 'THB', country: 'Thai Baht' },
  { id: 'TJS', country: 'Tajikistani Somoni' },
  { id: 'TMT', country: 'Turkmenistan Manat' },
  { id: 'TND', country: 'Tunisian Dinar' },
  { id: 'TOP', country: 'Tongan Pa`anga' },
  { id: 'TRY', country: 'Turkish Lira' },
  { id: 'TTD', country: 'Trinidad and Tobago Dollar' },
  { id: 'TVD', country: 'Tuvaluan Dollar' },
  { id: 'TWD', country: 'New Taiwan Dollar' },
  { id: 'TZS', country: 'Tanzanian Shilling' },
  { id: 'UAH', country: 'Ukrainian Hryvnia' },
  { id: 'UGX', country: 'Ugandan Shilling' },
  { id: 'UYU', country: 'Uruguayan Peso' },
  { id: 'UZS', country: "Uzbekistani So'm" },
  { id: 'VES', country: 'Venezuelan Bolívar Soberano' },
  { id: 'VND', country: 'Vietnamese Đồng' },
  { id: 'VUV', country: 'Vanuatu Vatu' },
  { id: 'WST', country: 'Samoan Tālā' },
  { id: 'XAF', country: 'Central African CFA Franc' },
  { id: 'XCD', country: 'East Caribbean Dollar' },
  { id: 'XDR', country: 'Special Drawing Rights' },
  { id: 'XOF', country: 'West African CFA franc' },
  { id: 'XPF', country: 'CFP Franc' },
  { id: 'YER', country: 'Yemeni Rial' },
  { id: 'ZAR', country: 'South African Rand' },
  { id: 'ZMW', country: 'Zambian Kwacha' },
  { id: 'ZWL', country: 'Zimbabwean Dollar' },
];
